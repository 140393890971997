import React from 'react';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';
import {
  CompanyProfileCell,
  CompanyHasCredentialsCell,
  CustomerDocumentsCell,
  DateTimeCell,
  TextCell,
} from '../components/cells';
import {
  AvatarCell,
  CollectionPriorityCell,
  AvatarExecutiveAssignedCell,
} from '../components/dataGridCells';
import ProgressBarCell from '../components/cells/ProgressBarCell';
import FingoGridNode from '../components/dataGrids/FingoGridNode';

const useMasterEntityPreColumns = (
  customMasterEntityColumns = [],
  showRelatedDocuments = false,
) => {
  const masterEntityDefaultPreColumns = [
    {
      field: 'name',
      type: 'string',
      headerName: 'Empresa',
      renderCell: ({ row }) => (
        <CompanyProfileCell
          masterEntity={row}
          showRelatedDocuments={showRelatedDocuments}
        />
      ),
      sortable: true,
      flex: 2,
    },
    {
      field: 'nameWithProfile',
      type: 'string',
      headerName: 'Empresa',
      renderCell: ({ row }) => <CompanyProfileCell masterEntity={row} />,
      sortable: true,
      flex: 2,
    },
    {
      field: 'documents',
      headerName: 'Documentos',
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => (row.company ? (
        <CustomerDocumentsCell masterEntity={row} showGenerateContract wrap />
      ) : (
        <></>
      )),
      flex: 4,
    },
    {
      field: 'hasCredentials',
      headerName: 'Credenciales',
      filterable: false,
      sortable: false,
      maxWidth: 100,
      flex: 2,
      renderCell: ({ row }) => (row.company ? (
        <CompanyHasCredentialsCell company={row.company} />
      ) : (
        <></>
      )),
    },
    {
      field: 'creditLine_LastUpdate',
      type: 'date',
      headerName: 'Fecha última actualización',
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => (
        <DateTimeCell fullDate={moment(row?.creditLine?.lastUpdate)} />
      ),
      flex: 1,
    },
    {
      field: 'creditLine_LastEvaluationDate',
      type: 'date',
      headerName: 'Fecha solicitud evaluación',
      sortable: false,
      filterable: false,
      renderCell: () => <TextCell text="N/D" />,
      flex: 1,
    },
    {
      field: 'creditLine_CreditUsed',
      headerName: 'Línea usada',
      sortable: false,
      filterable: false,
      flex: 2,
      renderCell: ({ row }) => (
        <ProgressBarCell
          value={row.creditLine?.creditUsed ?? 0}
          total={row.creditLine?.currentLimit?.newLimit?.amount ?? 0}
          isMoney
          currency={row.creditLine?.currency?.isoCode ?? 'CLP'}
          startTooltipTitle="Línea usada"
          endTooltipTitle="Línea total"
        />
      ),
    },
    {
      field: 'company_ExecutiveAssigned__firstName',
      headerName: 'Ejecutivo',
      filterable: true,
      sortable: true,
      maxWidth: 90,
      flex: 1,
      valueGetter: (params) => params.row.company?.executiveAssigned?.firstName,
      renderCell: (params) => (
        <AvatarExecutiveAssignedCell
          companyId={params.row.id}
          user={params.row.company?.executiveAssigned}
          assignationType="executive"
        />
      ),
    },
    {
      field: 'debtor_RatificationExecutive__firstName',
      headerName: 'Ejecutivo Ratificación',
      filterable: true,
      sortable: true,
      maxWidth: 150,
      flex: 1,
      valueGetter: ({ row }) => row.debtor?.ratificator,
      renderCell: ({ row, value }) => (
        <AvatarExecutiveAssignedCell
          companyId={row.id}
          user={value}
          assignationType="ratification"
        />
      ),
    },
    {
      field: 'debtor_CollectionExecutive__firstName',
      headerName: 'Ejecutivo Cobranza',
      filterable: true,
      sortable: true,
      maxWidth: 150,
      flex: 1,
      valueGetter: ({ row }) => row.debtor?.collector?.firstName,
      renderCell: ({ row }) => (
        <AvatarExecutiveAssignedCell
          companyId={row.id}
          user={row.debtor?.collector}
          assignationType="collection"
        />
      ),
    },
    {
      field: 'recommendedInvoices',
      headerName: 'Recomendaciones',
      filterable: false,
      sortable: false,
      maxWidth: 130,
      flex: 3,
      valueGetter: (params) => params.row.company?.recommendedInvoices.length,
    },
    {
      field: 'debtor_Collector_FirstName',
      headerName: 'Cobrador',
      type: 'string',
      maxWidth: 80,
      sortable: true,
      filterable: false,
      flex: 1,
      valueGetter: (params) => params.row.debtor?.collector,
      renderCell: (params) => <AvatarCell user={params.value} />,
    },
    {
      field: 'debtor_CollectionPriorities_Priority_Value',
      headerName: 'Prioridad',
      type: 'number',
      sortable: true,
      filterable: false,
      maxWidth: 80,
      flex: 1,
      renderCell: ({ row }) => (
        <CollectionPriorityCell
          priority={row.debtor?.collectionPriority?.priority?.value}
        />
      ),
    },
    {
      field: 'debtor_RatificationPriority_Value',
      headerName: 'Prioridad',
      type: 'number',
      sortable: true,
      filterable: false,
      maxWidth: 80,
      flex: 1,
      valueGetter: (params) => params.row.debtor?.ratificationPriority?.value,
      renderCell: (params) => (
        <CollectionPriorityCell priority={params.value} />
      ),
    },
    {
      field: 'controlGroupMails',
      headerName: 'Mails a los que pertenece',
      type: 'string',
      sortable: false,
      filterable: false,
      flex: 3,
      renderCell: ({ row }) => (
        <Stack direction="row" spacing={1}>
          {row.company?.controlGroupDetail.map(({ mail }) => (
            <Typography key={mail.id}>{mail.emailName} </Typography>
          ))}
        </Stack>
      ),
    },
  ];
  const masterEntityPreColumns = masterEntityDefaultPreColumns.map(
    (defaultColumn) => customMasterEntityColumns.find(
      (customColumn) => customColumn.field === defaultColumn.field,
    ) || defaultColumn,
  );
  const newPreColumns = customMasterEntityColumns.filter(
    (customColumn) => !masterEntityDefaultPreColumns
      .map((defaultColumn) => defaultColumn.field)
      .includes(customColumn.field),
  );
  const columns = [...masterEntityPreColumns, ...newPreColumns];
  return columns.map(({ renderCell, ...restParams }) => {
    if (renderCell) {
      return {
        ...restParams,
        renderCell: (params) => (
          <FingoGridNode params={params} renderCell={renderCell} />
        ),
      };
    }
    return restParams;
  });
};

export default useMasterEntityPreColumns;
